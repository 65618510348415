import {Axios} from 'axios-observable';
import router from "@/router";
import {Alert} from "@/addons/Alert";
import {globalStore} from "@/store/globalStore";
import {GlobalService} from "@/services/GlobalService";

export const http = Axios.create({
    baseURL: `${process.env.VUE_APP_URL}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})

const requestHandler = (request:any) => {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
    return request;
};

const responseHandler = (response:any) => {
    return response;
};

const errorHandler = (error:any) => {
    return Promise.reject(error);
};

http.interceptors.request.use(
    async (request:any) => requestHandler(request),
    (error:any) => errorHandler(error)
);

http.interceptors.response.use(
    (response:any) => responseHandler(response),
    (error:any) => {
        if (error.response.status === 401) {
            redirigirLogin()
        }
        if (error.response.status === 404) {
            router.push({name: 'error'});
        }
        if (error.response.status === 403) {
            router.push({name: 'error_permisos'});
        }
        if (error.response.status === 402) {
            const errors = error.response.data.errors;
            let errosString = "";
            for (const field of Object.keys(errors)) {
                errosString += "- " + errors[field][0] + "<br>";
            }

            Alert.error(errosString);
        }
        if (error.response.status !== 1) //error 500, 400, etc
        {
            Alert.error(`
                            ${error.response.data.message} <br/>
                            ${error.response.data?.error}
                        `);
        }
    }
);

function redirigirLogin() {
    Alert.errorTop("Se ha cerrado sesión desde otra máquina, o su tiempo de inactividad a caducado. Vuelva a ingresar al sistema")
    GlobalService.getInstance().destroyToken().then(() => {
        window.location.href = globalStore().urlYakusela;
    }).catch(() => {
        Alert.errorTop("Se ha cerrado sesión con algún error, le recomendamos que ingrese al sistema y cierre sesión para solucionar los problemas");
        window.location.href = globalStore().urlYakusela;
    });
}
