
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "DatosBasicos",
	})

	export default class DatosBasicos extends Vue {	
	globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	evento_form= {
		id: '',
		detalle: '',
		fecha: DateTime.now().toFormat('yyyy-MM-dd'),
		imagen: '',
	}
	valid = true;
	preview = null;
	//image = null;
	image = null;
	imageUrl = '';
	artista = '';
	precio_artista = 0;
	artistas = [] as Array<any>;
	tipo_entrada = null;
	precio_preventa = 0;
	precio_puerta = 0;
	comision = 0;
	detalle_entradas = [] as Array<any>
	
	public url_base = `${process.env.VUE_APP_URL_IMAGE}`
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	
	get evento(): any {
		return this.eventoStore.evento;
	}
	get tipo_entradas(): any {
		return this.eventoStore.tipo_entradas;
	}
	get evento_entradas(): any {
		return this.eventoStore.evento_entradas;
	}
	async mounted(){
		await this.cargarDatos()
		await this.eventoService.getTipoEntradas();
	}

	guardar(){
		console.log(this.image)
		if (!this.$refs.form.validate()) {
			Alert.warning( `Lene todos los campos obligatorios (*)`)
			return;
		}
		Alert.confirm(`Se modificará el evento ${this.evento_form.detalle} ${this.evento_form.fecha}`, 'Modificar Evento?').then(async result => {
			if(result.isConfirmed){
				const formData = new FormData(); 
        formData.append('detalle',this.evento.detalle);
        formData.append('fecha',this.evento.fecha);
        formData.append('imagen',this.image);
				formData.append('detalle_entradas', JSON.stringify(this.evento_entradas))
				await this.eventoService.actualizarEvento(Number(this.$route.query.id),formData).then(response =>{
					//this.limpiar()
					//router.push({name: 'pacientes'});
				})
			}
		})
	}


	limpiar(){
		this.evento_form = {
			id: '',
			detalle: '',
			fecha: DateTime.now().toFormat('yyyy-MM-dd'),
			imagen: '',
		}
	}
	
	createImage(file:any) {
		console.log(file)
		const reader = new FileReader();
		
		reader.onload = (e) => {
			this.imageUrl = e.target.result;
		};
		reader.readAsDataURL(file);
	}
	onFileChange(file:any) {
		if (!file) {
			return;
		}
		this.createImage(file);
	}
	agregarEntrada() {
		if(this.tipo_entrada == null || this.precio_preventa < 0 || this.precio_puerta < 0 || this.comision < 0  ) {
			Alert.warning('Ingrese datos correctos')
			return
		}
		
		this.evento_entradas.push({
			tipo: this.tipo_entrada.tipo, 
			precio_preventa: this.precio_preventa, 
			precio_puerta: this.precio_puerta, 
			comision: this.comision,
			entrada_id: this.tipo_entrada.id
		})
		this.precio_preventa = 0;
		this.precio_puerta = 0;
		this.comision = 0;
	}
	
	quitarEntrada(index:number) {
		this.evento_entradas.splice(index, 1)
	}
	async cargarDatos()
	{
		await this.eventoService.showEvento(Number(this.$route.query.id)).then(res => {
			this.evento_form =  this.evento
			this.imageUrl =  this.url_base+'public/imagenes/'+this.evento.imagen
		})
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}

}

