import {defineStore} from "pinia";


export const useEventoStore = defineStore("eventoStore", {
    state: () =>
        ({
            loading: false as boolean,
            showDialog: false as boolean,
            eventos: [],
            productos: [],
            cajas: [],
            evento: {},
            ventas: [],
            categorias: [],
            stock: [],
            egresos: [],
            tipo_entradas: [],
            evento_entradas: [],
            artistas: [],
            evento_promotores: [],
            promotores: [],
            venta_entradas: [],
            usuarios: [],
            boxes: [],
            
            pagination: {
              per_page: 0,
              current_page: 1,
              last_page: 0,
            },
            
        }),
});