import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import NuevaVentaAgua from "@/views/cisterna/NuevaVenta.vue"
import IndexEventos from "@/views/eventos/Index.vue"
import FormEvento from "@/views/eventos/Form.vue"
import ProductosIndex from '@/views/productos/Index.vue'
import DetalleForm from '@/views/eventos/Detalle.vue'
import RegistrarVenta from '@/views/ventas/RegistrarVenta.vue'
import UsuariosIndex from '@/views/usuarios/Index.vue'
import PromotoresIndex from '@/views/promotores/Index.vue'
import BarraIndex from '@/views/barras/Index.vue'

import {UserAuth} from "@/models/UserAuth";
import {Cifrado} from "@/addons/Cifrado";


Vue.use(VueRouter)
// function can(name: string) {
//     const permisos = JSON.parse(localStorage.getItem("permisos_creditos")!);
//     return permisos.find((x:any) => x.name === name) !== undefined;
//   }
  function can(slug: string) {
    const permisos = JSON.parse(localStorage.getItem("permisosCreditos")!);
    return permisos?.find((x:any) => Cifrado.decrypt(x.slug) === slug) !== undefined;
}

const routes = [
    {
        path: '/',
        redirect: '/login' // Redirige la raíz a la página de inicio de sesión
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Login.vue'),
        //meta: { requiresAuth: false }
    },
    {

        path: '/',
        component: () => import('@/layouts/default/View.vue'),
        children: [
            {
                path: '/home',
                name: 'home',
                meta: { requiresAuth: true },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "home" */ '@/views/AboutView.vue'),
            },
            {
                path: '/eventos',
                name: 'eventos',
                component: IndexEventos,
                meta: {requiresAuth: true},
                props: true,
                beforeEnter: (to:any, from:any, next:any) => {
                    if (can('Administrador') || can('Supervisor')) next()
                    else next({name: "error_permisos"})
                }
            },
            {
                path: '/nuevo-evento',
                name: 'nuevo-evento',
                component: FormEvento,
                meta: {requiresAuth: true},
                props: true,
                beforeEnter: (to:any, from:any, next:any) => {
                    if (can('Administrador')) next()
                    else next({name: "error_permisos"})
                }
            },
            {
                path: '/productos',
                name: 'productos',
                component: ProductosIndex,
                meta: {requiresAuth: true},
                props: true,
                beforeEnter: (to:any, from:any, next:any) => {
                    if (can('Administrador')) next()
                    else next({name: "error_permisos"})
                }
            },
            {
                path: '/detalle',
                name: 'detalle',
                component: DetalleForm,
                meta: {requiresAuth: true},
                props: true,
                beforeEnter: (to:any, from:any, next:any) => {
                    if (can('Administrador') || can('Supervisor')) next()
                    else next({name: "error_permisos"})
                }
            },
            {
                path: '/ventas',
                name: 'ventas',
                component: RegistrarVenta,
                meta: {requiresAuth: true},
                props: true,
                beforeEnter: (to:any, from:any, next:any) => {
                    if (can('Cajero')) next()
                    else next({name: "error_permisos"})
                }
            },
            {
                path: '/usuarios',
                name: 'usuarios',
                component: UsuariosIndex,
                meta: {requiresAuth: true},
                props: true,
                beforeEnter: (to:any, from:any, next:any) => {
                    if (can('Administrador')) next()
                    else next({name: "error_permisos"})
                }
            },
						{
							path: '/promotores',
							name: 'promotores',
							component: PromotoresIndex,
							meta: {requiresAuth: true},
							props: true,
							beforeEnter: (to:any, from:any, next:any) => {
									if (can('Administrador')) next()
									else next({name: "error_permisos"})
							}
						},
						{
							path: '/barras',
							name: 'barras',
							component: BarraIndex,
							meta: {requiresAuth: true},
							props: true,
							beforeEnter: (to:any, from:any, next:any) => {
									if (can('Administrador')) next()
									else next({name: "error_permisos"})
							}
						},
            
            
],
},
   


    {
        path: '/404',
        name: 'error',
        component: () => import('../components/Error.vue'),
    },
    {
        path: '/403',
        name: 'error_permisos',
        component: () => import('../components/ErrorPermisos.vue'),
    },
    {
        path: '/',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next)  => {
    if (to.meta?.requiresAuth && !localStorage.getItem('token')) {
        console.log('sdsdsds')
        next({path: '/login'});
      } else {
        console.log('aqui')
        next();
      }
});

export default router
