
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "VentaEntradas",
	})

	export default class VentaEntradas extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	
	valid = true;
	preview = null;
	evento_entrada_id = null;
	cantidad = 0;
	precio = 0;
	detalle = '';
	venta_entrada_id = null
	swEditar = false;
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	
	
	get evento_promotores(): any {
		return this.eventoStore.evento_promotores;
	}
	get venta_entradas(): any {
		return this.eventoStore.venta_entradas;
	}
	get evento_entradas(): any {
		return this.eventoStore.evento_entradas;
	}
	get totales(): any {
		let total = 0, cantidad = 0;
		this.venta_entradas.reduce((sum, val) => {
			total += Number(val.subtotal);
			cantidad += Number(val.cantidad);
		},0)
		return { total: Number(total).toFixed(2), cantidad: Number(cantidad).toFixed(2) }
	}
	
	async mounted(){
		//await this.cargarDatos()
		await this.eventoService.getPromotores();
		await this.eventoService.getTipoEntradas();
	}


	registrarVentaEntrada() {
		console.log('llega')
		if(this.evento_entrada_id == null || this.cantidad <=0 || this.precio <= 0 ) {
			Alert.warning('Ingrese datos correctos')
			return
		}
		let data = {
			evento_id: this.$route.query.id,
			evento_entrada_id: this.evento_entrada_id,
			cantidad: this.cantidad,
			precio: this.precio,
			subtotal: Number(this.cantidad * this.precio),
			detalle: this.detalle
		}
		if(!this.swEditar){
			this.eventoService.registrarVentaEntrada(data).then(response => {
				this.cantidad = 0;
				this.precio = 0;
				this.detalle = ''
				this.eventoService.showEvento(Number(this.$route.query.id))
			})

		}else {
			this.eventoService.modificarVentaEntrada(Number(this.venta_entrada_id),data).then(response => {
				this.cantidad = 0;
				this.precio = 0;
				this.detalle = ''
				this.swEditar = false;
				this.eventoService.showEvento(Number(this.$route.query.id))
			})
		}
		
	}
	
	editar(venta:any) {
		this.evento_entrada_id = venta.evento_entrada_id;
		this.cantidad = venta.cantidad;
		this.precio = venta.precio;
		this.detalle = venta.detalle;
		this.venta_entrada_id = venta.id;
		this.swEditar = true;
	}
	eliminarVenta(id:number) {
		Alert.confirm('Se eliminara la venta de entradas','Eliminar Venta?').then(async result => {
			if(result.isConfirmed){
				await this.eventoService.eliminarVentaEntradas(id).then(response =>{
					this.eventoService.showEvento(Number(this.$route.query.id))
					//router.push({name: 'pacientes'});
				})
			}
		})
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}
	

}

