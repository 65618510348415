
	import {Component, Prop, Watch, Vue} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
    
	@Component({
		name: "FormPromotores"
	})
	export default class FormPromotores extends Vue {
		@Prop() dialog!: boolean
		@Prop() promotor_selected!: object
		@Prop() swEditar!: boolean
  
		private globalStore = globalStore();
		private eventoStore = useEventoStore();
		private eventoService = EventoService.getInstance();
    
		promotor = {
			id: null,
			nombre_completo: '',
			celular: '',
		};
		valid = true;
    
		mounted(){
			console.log(this.swEditar)
			setTimeout(this.cargarDatos,1200);
		}
		
		close(){
			this.limpiar();
			this.$emit("close");
		}
		guardar(){
			if (!this.$refs.form.validate()) {
				Alert.warning( `Lene todos los campos obligatorios (*)`)
					return;
				}
			if(!this.swEditar){
				Alert.confirm(`Se registrará el promotor ${this.promotor.nombre_completo} `, 'Registrar nuevo Promotor?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.registrarPromotor(this.promotor).then(response =>{
							this.limpiar()
							this.$emit('close')
							this.$emit('listar')
						})
						
					}
				})
			}else {
				Alert.confirm(`Se realizará la modificacion del promotor`, 'Modificar Promotor?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.modificarPromotor(this.promotor)
						this.limpiar()
						this.$emit('close')
						this.$emit('listar')
					}
				})
			}
			
		}
		cargarDatos(){
			console.log(this.swEditar)
			if(this.swEditar == false){
				this.limpiar()
				return
			}
			console.log('cargar')
			if( Object.entries(this.promotor_selected).length !== 0 && this.promotor_selected.constructor === Object){
				//this.swEditar = false;
				console.log('entra')
				this.promotor = {
					id: this.promotor_selected.id,
					nombre_completo: this.promotor_selected.nombre_completo,
					celular: this.promotor_selected.celular,
				};
			}
		}
		limpiar(){
			this.promotor = {
				id: null,
				nombre_completo: '',
				celular: '',
			};
		}
		get loading(): boolean {
			return this.eventoStore.loading;
		}
		
      
	}
