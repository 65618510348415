
  //import {Vue} from "vue-property-decorator";
  //import Component from "vue-class-component";
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import { Alert } from "@/addons/Alert";
  //import { http } from "@/config/HttpClient";
  import { DateTime } from "luxon";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  
  @Component({
    name: "ModalVenta",
  })
  
  export default class ModalVenta extends Vue {
    @Prop() stock!: any
    @Prop() evento_id!: boolean
    @Prop() swForm!: boolean
		@Prop() cajas!: any

    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
    private tab:any = null;
    public dialog = false;
    $refs!: {
      form: HTMLFormElement,
    };
    
    venta= {
        id: null,
        caja_id: '',
        cantidad_inicial: '',
        cantidad_final: 0,
        tipo_pago: 'EFECTIVO',
				cantidad: 0,
				stock_id : '',
				evento_id: ''
    }
    valid = true;
    fecha = DateTime.now().toFormat('yyyy-MM-dd');
    

    mounted(){
      this.dialog = this.swForm
    }

    close(){
      this.dialog=false;
    }
    
    guardar(){
      if (!this.$refs.form.validate()) {
        Alert.warning( `Lene todos los campos obligatorios (*)`)
          return;
        }
      this.venta.cantidad = Number(this.venta.cantidad_inicial) - Number(this.venta.cantidad_final);
			this.venta.stock_id = this.stock.id;
			this.venta.evento_id = this.stock.evento_id;
			Alert.confirm(`Se realizará el registro de la venta`, 'Guardar Venta?').then(async result => {
					if(result.isConfirmed){
					await this.eventoService.registrarVenta(this.venta)
					this.limpiar()
					this.$emit('close')
					//this.$emit('listar')
					}
			})
    
      
    }
    
    
    limpiar(){
      this.venta= {
        id: null,
        caja_id: '',
        cantidad_inicial: '',
        cantidad_final: 0,
        tipo_pago: 'EFECTIVO',
				cantidad: 0
      }
    }
    get loading(): boolean {
      return this.eventoStore.loading;
    }
   
  }
  
  