
	import {Component, Prop, Watch, Vue} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	
	@Component({
		name: "FormBarra"
	})
	export default class FormBarra extends Vue {
		@Prop() dialog!: boolean
		@Prop() barra_selected!: object
		@Prop() swEditar!: boolean

		private globalStore = globalStore();
		private eventoStore = useEventoStore();
		private eventoService = EventoService.getInstance();
	
		barra = {
			id: null,
			caja: '',
		};
		valid = true;
		
		mounted(){
			setTimeout(this.cargarDatos,1500);
		}
		
		close(){
			this.limpiar();
			this.$emit("close");
		}
		guardar(){
			if (!this.$refs.form.validate()) {
				Alert.warning( `Lene todos los campos obligatorios (*)`)
					return;
				}
			if(!this.swEditar){
				Alert.confirm(`Se registrará la barra ${this.barra.caja} `, 'Registrar nueva Barra?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.registrarBarra(this.barra).then(response =>{
							this.limpiar()
							this.$emit('close')
							this.$emit('listar')
						})
						
					}
				})
			}else {
				Alert.confirm(`Se modificará la barra ${this.barra.caja}`, 'Modificar Barra?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.modificarBarra(this.barra)
						this.limpiar()
						this.$emit('close')
						this.$emit('listar')
					}
				})
			}
			
		}
		cargarDatos(){
			console.log(this.swEditar)
			if(this.swEditar == false){
				this.limpiar()
				return
			}
			console.log('cargar')
			if( Object.entries(this.barra_selected).length !== 0 && this.barra_selected.constructor === Object){
				//this.swEditar = false;
				console.log('entra')
				this.barra = {
					id: this.barra_selected.id,
					caja: this.barra_selected.caja,
				};
			}
		}
		limpiar(){
			this.barra = {
				id: null,
				caja: '',
			};
		}
		get loading(): boolean {
			return this.eventoStore.loading;
		}
		
	}
