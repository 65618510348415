
  import {Vue} from "vue-property-decorator";
  import Component from "vue-class-component";
  import { Alert } from "../../addons/Alert";
  //import { http } from "@/config/HttpClient";
  import { DateTime } from "luxon";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  import router from "../../router";
  import FormPromotor from "./Form.vue"
  
  @Component({
    name: "PromotoresIndex",
    components: {FormPromotor}
  })
  export default class PromotoresIndex extends Vue {
    
    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
    private tab:any = null;
    public dialog = false;
    datos2 = [];
    fecha = DateTime.now().toFormat('yyyy-MM-dd');
		swForm = false;
		promotor_selected = {}
		
    get promotores(): any {
      return this.eventoStore.promotores;
    }
    
    public txtSearch = "";
    swEditar = false
    mounted(){
      this.getPromotores();
      //this.getRoles();

    }
    getPromotores(){
      this.loading
      this.eventoService.getPromotores()
        
    }

    
    nuevoPromotor(){
			this.swForm = true;
			this.swEditar = false;

    }
    editar(promotor:any){
			this.swForm = true;
			this.swEditar = true;
			this.promotor_selected = promotor
      
    }
   
    close(){
        this.swForm = false;
    }
		
    get loading(): boolean {
      return this.eventoStore.loading;
    }
    
  }
  