
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "PagosTab",
	})

	export default class PagosTab extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;
	motivo_gasto = '';
	monto_gasto = 0;
	gasto_id = null;
	valid = true;
	swEditar = false;
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	get egresos(): any {
		return this.eventoStore.egresos;
	}
	get total_gastos(): any {
		let total = 0;
		this.egresos.reduce((sum, val) => {
			total += Number(val.monto); 
		},0)
		return Number(total).toFixed(2)
	}
	mounted(){
		this.eventoService.getProductos()
	}

	agregarGasto(){
		if(this.motivo_gasto == '' || this.monto_gasto <= 0){
			Alert.warning('Ingrese datos validos')
			return;
		}
		let data = {
			evento_id: this.$route.query.id,
			detalle: this.motivo_gasto,
			cantidad: 0,
			monto: this.monto_gasto
		}
		if(!this.swEditar) {
			this.eventoService.registrarEgreso(data).then(response => {
				this.eventoService.showEvento(Number(this.$route.query.id))
				this.monto_gasto = 0;
				this.motivo_gasto = '';
			})
		}else {
			this.eventoService.updateEgreso(this.gasto_id,data).then(response => {
				this.eventoService.showEvento(Number(this.$route.query.id))
				this.monto_gasto = 0;
				this.motivo_gasto = '';
				this.swEditar = false;
			})
		}
	}
	editar(gasto:any) {
		this.swEditar = true;
		this.motivo_gasto = gasto.detalle;
		this.monto_gasto = gasto.monto;
		this.gasto_id = gasto.id
	}
	eliminar(id:number) {
		this.eventoService.eliminarEgreso(id);
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}
	

}

