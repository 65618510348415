
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "PromotoresTab",
	})

	export default class PromotoresTab extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	
	valid = true;
	evento_entrada_id = null;
	promotor_id = null;
	cantidad = 0;
	swEditar = false;
	evento_promotor_id = null
	swModal = false;
	nombre_promotor = '';
	cantidad_vendida = 0;
	tipo_entrada = '';
	evento_promotor = ''
	detalle = ''
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	
	
	get evento_promotores(): any {
		return this.eventoStore.evento_promotores;
	}
	get promotores(): any {
		return this.eventoStore.promotores;
	}
	get evento_entradas(): any {
		return this.eventoStore.evento_entradas;
	}
	get total(): any {
		let  total = 0, total_vendidos = 0, subtotal_entradas = 0, total_comision = 0;
		this.evento_promotores.reduce((sum,val) => {
			total += Number(val.cantidad_inicial);
			total_vendidos += Number(val.vendidos);
			subtotal_entradas += Number(val.subtotal_entradas);
			total_comision += Number(val.subtotal_comision);

		},0)
		return {total: Number(total).toFixed(2), 
						total_vendidos: Number(total_vendidos).toFixed(2),
						subtotal_entradas: Number(subtotal_entradas).toFixed(2),
						total_comision: Number(total_comision).toFixed(2)
					}
	}
	async mounted(){
		//await this.cargarDatos()
		await this.eventoService.getPromotores();
	}


	agregarPromotor() {
		console.log('llega')
		if(this.promotor_id == null || this.evento_entrada_id == null || this.cantidad <= 0 ) {
			Alert.warning('Ingrese datos correctos')
			return
		}
		let data = {
			evento_id: this.$route.query.id,
			promotor_id: this.promotor_id,
			evento_entrada_id: this.evento_entrada_id,
			cantidad_inicial: this.cantidad,
			detalle: this.detalle
		}
		if(!this.swEditar){
			this.eventoService.registrarEventoPromotor(data).then(response => {
				this.cantidad = 0;
				this.eventoService.showEvento(Number(this.$route.query.id))
			})

		}else {
			this.eventoService.modificarEventoPromotor(this.evento_promotor_id,data).then(response => {
				this.promotor_id = null;
				this.evento_entrada_id = null;
				this.cantidad = 0;
				this.swEditar = false;
				this.detalle = ''
				this.eventoService.showEvento(Number(this.$route.query.id))
			})
		}
		
	}
	
	editar(venta:any) {
		this.promotor_id = venta.promotor_id;
		this.evento_entrada_id = venta.evento_entrada_id;
		this.cantidad = venta.cantidad_inicial;
		this.evento_promotor_id = venta.id;
		this.detalle = venta.detalle;
		this.swEditar = true;
	}
	eliminarEventoPromotor(id:number) {
		this.eventoService.eliminarEventoPromotor(id).then(response => {
			console.log(response);
				this.eventoService.showEvento(Number(this.$route.query.id))
			})
	}
	abrirModal(evento_promotor:any) {
		this.evento_promotor_id = evento_promotor.id,
		this.nombre_promotor = evento_promotor.nombrePromotor;
		this.tipo_entrada = evento_promotor.evento_entrada.tipo;
		this.evento_promotor = evento_promotor;
		this.swModal = true;
	}
	registrarVentaEntrada() {
		if(this.cantidad_vendida <0 ) {
			Alert.warning('Ingrese una cantidad valida');
			return;
		}
		let data = {
			vendidos: this.cantidad_vendida,
			subtotal_entradas: Number(this.cantidad_vendida * this.evento_promotor.evento_entrada.precio_preventa),
			subtotal_comision: Number(this.cantidad_vendida * this.evento_promotor.evento_entrada.comision),
			evento_promotor_id: this.evento_promotor_id
		}
		this.eventoService.registrarEntradasPromotor(data).then(response => {
			this.cantidad_vendida = 0;
			this.swModal = false;
			console.log(response);
			this.eventoService.showEvento(Number(this.$route.query.id))
			
		})
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}
	

}

