
  import {Vue} from "vue-property-decorator";
  import Component from "vue-class-component";
  import { Alert } from "../../addons/Alert";
  //import { http } from "@/config/HttpClient";
  import { DateTime } from "luxon";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  import router from "../../router";
  import FormProducto from "./Form.vue"
  
  @Component({
    name: "ProductosIndex",
    components: {FormProducto}
  })
  export default class ProductosIndex extends Vue {
    
    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
    private tab:any = null;
    public dialog = false;
    datos2 = [];
    fecha = DateTime.now().toFormat('yyyy-MM-dd');
		swForm = false;
		producto_selected = {}
    get productos(): any {
      return this.eventoStore.productos;
    }
    
    public txtSearch = "";
    swEditar = false
    mounted(){
      this.getProductos();
      this.getCategorias();
    }
    getProductos(){
      this.loading
      this.eventoService.getProductos()
        
    }
    getCategorias(){
      this.eventoService.getCategorias()
    }
    nuevoProducto(){
			this.swForm = true;
			this.swEditar = false;

    }
    editar(producto:any){
			this.swForm = true;
			this.swEditar = true;
			this.producto_selected = producto
      
    }
   
    close(){
        this.swForm = false;
    }
    
    get pagination(): any {
      return this.eventoStore.pagination;
    }
    get loading(): boolean {
      return this.eventoStore.loading;
    }
    
  }
  