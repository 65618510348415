
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";
	import ModalVenta from './ModalVenta.vue'
	import DatosBasicos from './DatosBasicos.vue'
	import StockProductos from './StockProductos.vue'
	import PagosTab from './Pagos.vue'
	import BalanceTab from './Balance.vue'
	import ArtistasForm from './Artistas.vue'
	import PromotoresTab from './Promotores.vue'
	import VentasTab from './Ventas.vue'
	import VentaEntradas from './VentaEntradas.vue'
	import BoxTab from "./Box.vue";

	@Component({
	name: "DetalleForm",
	components: {ModalVenta, DatosBasicos, StockProductos, PagosTab, BalanceTab, ArtistasForm, PromotoresTab, VentasTab, VentaEntradas, BoxTab}
	})

	export default class DetalleForm extends Vue {	

	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	public tab:any = null;
	evento = '';
	valid = true;
	preview = null;
	//image = null;
	artistas = [] as Array<any>;
	productos_evento = [] as Array<any>;
	producto = null;
	adicional = 0;
	cantidad_final = 0;
	pagos = [] as Array<any>;
	pago_concepto = '';
	monto_pago = 0;
	cantidad_pago = 1;
	swVenta = false;
	stock_selected = {};
	motivo_gasto = '';
	monto_gasto = 0;
	egresos = [] as Array<any>
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	get productos(): any {
		return this.eventoStore.productos;
	}
	get cajas(): any {
		return this.eventoStore.cajas;
	}
	get ventas(): any {
		return this.eventoStore.ventas;
	}


	get totales_venta(): any {
		let total_costo_unitario = 0, costo_unitario = 0, precio_venta = 0, total_precio_venta = 0, ganancia_bruta = 0;
		this.ventas.reduce((sum, venta) => {
			total_costo_unitario += Number(venta.producto.costo * venta.cantidad_vendido);
			costo_unitario += Number(venta.producto.costo);
			precio_venta += Number(venta.producto.precio_venta);
			total_precio_venta += Number(venta.producto.precio_venta * venta.cantidad_vendido);
			ganancia_bruta += Number((venta.producto.precio_venta * venta.cantidad_vendido) - (venta.producto.costo * venta.cantidad_vendido));

		},0)
		return {total_costo_unitario: Number(total_costo_unitario).toFixed(2), 
						costo_unitario: Number(costo_unitario).toFixed(2), 
						precio_venta: Number(precio_venta).toFixed(2), 
						total_precio_venta: Number(total_precio_venta).toFixed(2), 
						ganancia_bruta: Number(ganancia_bruta).toFixed(2)}
	}
	get total_gastos(): any {
		let total = 0;
		this.egresos.reduce((sum, val) => {
			total += Number(val.monto); 
		},0)
		return Number(total).toFixed(2)
	}
	mounted(){
		//this.eventoService.getProductos()
		//this.eventoService.getCajas();
		//setTimeout(this.cargarDatos,1500);
	}

	guardar(){
		if (!this.$refs.form.validate()) {
			Alert.warning( `Lene todos los campos obligatorios (*)`)
			return;
		}
		Alert.confirm(`Se realizará el registro del evento ${this.evento.detalle} ${this.evento.fecha}`, 'Registrar Evento?').then(async result => {
			if(result.isConfirmed){
				const formData = new FormData(); 
        formData.append('detalle',this.evento.detalle);
        formData.append('fecha',this.evento.fecha);
        formData.append('imagen',this.image);
				formData.append('productos', JSON.stringify(this.productos_evento))
				formData.append('artistas', JSON.stringify(this.artistas))
				await this.eventoService.registrarEvento(formData).then(response =>{
					//this.limpiar()
					//router.push({name: 'pacientes'});
				})
			}
		})
	}


	limpiar(){
		// this.evento = {
		// 	id: '',
		// 	detalle: '',
		// 	fecha: DateTime.now().toFormat('yyyy-MM-dd'),
		// 	imagen: '',
		// }
	}
	close(){
		router.push({path: 'eventos'});
	}
	
	agregarPago() {
		console.log('llega')
		if(this.pago_concepto == null || this.cantidad_pago <= 0 || this.monto_pago ) {
			Alert.warning('Productos: Ingrese datos correctos')
			return
		}
		let existente = null;
		existente = this.productos_evento.find(val => val.producto_id == this.producto.id);
		if(existente != null){
			Alert.warning('El producto ya fue ingresado')
			return
		}		
		this.productos_evento.push({producto: this.producto?.nombre, cantidad: this.cantidad, producto_id: this.producto?.id})
	}
	eliminarArtista(index:number) {
		this.artistas.splice(index, 1)
	}
	async cargarDatos()
	{
		await this.eventoService.showEvento(Number(this.$route.query.id))
	}
	abrirModalVenta(stock:any) {
		this.swVenta = true;
		this.stock_selected = stock;
	}
	agregarGasto(){
		if(this.motivo_gasto == '' || this.monto_gasto <= 0){
			Alert.warning('Ingrese datos validos')
			return;
		}
		this.egresos.push({detalle: this.motivo_gasto, monto: this.monto_gasto});
		this.monto_gasto = 0;
		this.motivo_gasto = '';
	}

}

