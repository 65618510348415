
import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import { Alert } from "../../addons/Alert";
//import { http } from "@/config/HttpClient";
import { DateTime } from "luxon";
import { useEventoStore } from '../../store/eventoStore';
import { globalStore } from '../../store/globalStore';
import { EventoService } from '../../services/EventoService';
import router from "../../router";

@Component({
name: "RegistrarVenta",
})
export default class RegistrarVenta extends Vue {

private globalStore = globalStore();
private eventoStore = useEventoStore();
private eventoService = EventoService.getInstance();
private tab:any = null;
public dialog = false;
datos2 = [];
fecha = DateTime.now().toFormat('yyyy-MM-dd');
swForm = false;
producto_selected = {}
ventas = [] as Array<any>
tipo_pago = null
public txtSearch = "";
swEditar = false
swModal = false
baseURL= `${process.env.VUE_APP_URL_IMAGE}`
get productos(): any {
		return this.eventoStore.productos;
}
	get total_ventas(): any {
		let total = 0, cantidad_total = 0;
		this.ventas.reduce((sum, val) => {
			total = total + Number(val.subtotal);
			cantidad_total = cantidad_total + Number(val.cantidad)
		},0)
		return {total: Number(total).toFixed(2), cantidad_total: Number(cantidad_total).toFixed(2)}
	}
mounted(){
    this.getProductos();
		this.eventoService.getStockEvento({id: 7});
		this.eventoService.getCategorias();
}
getProductos(){
    this.loading
    this.eventoService.getProductos()
    
}
close(){
    this.swForm = false;
}
seleccionarProducto(stock:any) {
	console.log('sdsdsd')
	let existe = this.ventas.find(val => val.stock_id == stock.id);
	if(existe){
		existe.cantidad ++;
		existe.subtotal = existe.cantidad * existe.precio_unitario;
	}else{
		this.ventas.push({
			stock_id: stock.id, 
			producto: stock.producto.nombre, 
			cantidad: 1, 
			precio_unitario: stock.producto.precio_venta, 
			subtotal: stock.producto.precio_venta, 
			evento_id: stock.evento_id,
			combo: stock.producto.combo
		})
	}
}
filtroCategoria(id:number) {
	return this.stock.filter(val => val.producto.categoria_id == id)
}
quitarProducto(index:number) {
	this.ventas.splice(index, 1)
}
confirmarVenta() {
	if(this.tipo_pago == null){
		Alert.warning('Seleccione el tipo de pago');
		return
	}
	let data = {
		ventas: this.ventas,
		tipo_pago: this.tipo_pago,
		caja_id: 1
	}
	this.eventoService.registrarVenta(data).then(res => {
		if(res.res == true){
			this.ventas = [];
			this.swModal = false;
		}
	})
}


get loading(): boolean {
    return this.eventoStore.loading;
}
get stock(): any {
    return this.eventoStore.stock;
}
get categorias(): any {
    return this.eventoStore.categorias;
}

}
