import {http} from "@/config/HttpClient";
import {Alert} from "@/addons/Alert";
import {globalStore} from "@/store/globalStore";
import { useEventoStore } from "@/store/eventoStore";
import {AxiosResponse} from "axios";
import {DateTime} from 'luxon';


export class EventoService {
	private static instance: EventoService;
	baseURL= `${process.env.VUE_APP_URL}`;
	public static getInstance() {
			return this.instance || (this.instance = new this());
	}

	globalStore = globalStore();
	eventoStore = useEventoStore();
	

	public async getEventos(data:any) {	
		await http.get(`eventos`,{params: data}).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.eventos = response.data;
				},
		})
	}
	public async getProductos() {	
		await http.get(`productos`,).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.productos = response.data;
				},
		})
	}
	public async registrarProducto(data: any): Promise<any> {
		const configu = { headers: { 'Content-Type' : 'multipart/form-data'}}
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("productos", data, configu).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async updateProducto(id:number, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
		const configu = { headers: { 'Content-Type' : 'multipart/form-data'}}
				this.eventoStore.loading = true;
				http.post(`actualizar-producto/${id}`, data, configu).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	
	public async registrarEvento(data: any): Promise<any> {
		const configu = { headers: { 'Content-Type' : 'multipart/form-data'}}
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("eventos", data, configu).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async actualizarEvento(id:number,data: any): Promise<any> {
		const configu = { headers: { 'Content-Type' : 'multipart/form-data'}}
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`actualizar-evento/${id}`, data, configu).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async getCajas() {	
		await http.get(`cajas`,).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.cajas = response.data;
				},
		})
	}
	
	public async showEvento(id:number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.get(`eventos/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								this.eventoStore.evento = response.data;
								this.eventoStore.stock = response.data.stock;
								this.eventoStore.egresos = response.data.egresos;
								this.eventoStore.ventas = response.data.ventas;
								this.eventoStore.evento_entradas = response.data.evento_entradas;
								this.eventoStore.artistas = response.data.artistas;
								this.eventoStore.evento_promotores = response.data.evento_promotores;
								this.eventoStore.venta_entradas = response.data.venta_entradas;
								this.eventoStore.boxes = response.data.boxes;

								resolve(response.data);
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
    
	public async registrarVentaOld(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("ventas", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async getCategorias() {	
		await http.get(`categorias`).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.categorias = response.data;
				},
		})
	}
	public async getStockEvento(data:any) {	
		await http.get(`get-stock-evento`,data).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.stock = response.data;
				},
		})
	}
	public async registrarVenta(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("ventas", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async registrarStock(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("stock", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async eliminarStock(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.delete(`stock/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async registrarEgreso(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("egresos", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async updateEgreso(id:number, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`egresos/${id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async eliminarEgreso(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.delete(`egresos/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async getTipoEntradas() {	
		await http.get(`tipo-entradas`).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.tipo_entradas = response.data;
				},
		})
	}
	public async registrarArtista(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("evento-artistas", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async modificarArtista(id:number, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`evento-artistas/${id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async getPromotores() {	
		await http.get(`promotores`,).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.promotores = response.data;
				},
		})
	}

	public async registrarEventoPromotor(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("evento-promotores", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async modificarEventoPromotor(id:number, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`evento-promotores/${id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async registrarVentaEntrada(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post("venta-entradas", data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async modificarVentaEntrada(id:number, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`venta-entradas/${id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async registrarPago(data: any): Promise<any> {
		const configu = { headers: { 'Content-Type' : 'multipart/form-data'}}
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`pago-artistas`, data, configu).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async eliminarPago(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.delete(`pago-artistas/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async registrarAdicional(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`registrar-adicional/${data.stock_id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
  public async eliminarEventoPromotor(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.delete(`evento-promotores/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async registrarEntradasPromotor(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`registrar-venta-promotor/${data.evento_promotor_id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async getUsuarios() {	
		await http.get(`get-usuarios`).subscribe({
				next: (response:AxiosResponse) => {
						this.eventoStore.usuarios = response.data;
				},
		})
	}

	public async registrarUsuario(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`nuevo-usuario`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async modificarUsuario(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`actualizar-usuario/${data.id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async restaurarPassword(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`restaurar-password`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async cambiarEstado(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.patch(`cambiar-estado/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async registrarBox(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`evento-box`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async registrarProductoBox(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`producto-box`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async registrarVentaBox(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`venta-box/${data.id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async eliminarVentaEntradas(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.delete(`venta-entradas/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async registrarPromotor(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`promotores`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async modificarPromotor(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`promotores/${data.id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async registrarBarra(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.post(`cajas`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}

	public async modificarBarra(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.put(`cajas/${data.id}`, data).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	public async eliminarCombo(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
				this.eventoStore.loading = true;
				http.delete(`combo-producto/${id}`).subscribe({
						next: (response: AxiosResponse) => {
								this.eventoStore.loading = false;
								if (response.data.res) {
										Alert.successTotastr(response.data.message,"Exito");
										resolve(response.data);
								} else {
										Alert.info( `<h1 style="color: red">${response.data.message}</h1>`)
										resolve(false);
								}
						},
						error: (error) => {
								this.eventoStore.loading = false;
								reject(error);
						}
				})
		});
	}
	
  validarPermiso() {
		return this.globalStore.permisos.some(val => val.name === 'Supervisor');
	}	
	makePagination(page: any) {
		this.eventoStore.pagination.per_page = page.per_page;
		this.eventoStore.pagination.current_page = page.current_page;
		this.eventoStore.pagination.last_page = page.last_page;
}
}