
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "StockProductos",
	})

	export default class StockProductos extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	valid = true;
	producto = null;
	cantidad = 0;
	swModal = false;
	nombre_producto = '';
	adicional = 0;
	stock_id = null
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	get productos(): any {
		return this.eventoStore.productos;
	}
	get stock(): any {
		return this.eventoStore.stock;
	}
	get totales(): any {
		let total = 0, cantidad_total = 0;
		this.stock.reduce((sum, val) => {
			cantidad_total = cantidad_total + Number(val.total)
			total = total + (Number(val.total) * val.producto.costo) 
		},0)
		return {total: Number(total).toFixed(2), cantidad_total: Number(cantidad_total).toFixed(2)}
	}
	mounted(){
		this.eventoService.getProductos()
	}

	agregarProducto() {
		console.log('llega')
		if(this.producto == null || this.cantidad <= 0 ) {
			Alert.warning('Productos: Ingrese datos correctos')
			return
		}
		let existente = null;
		existente = this.stock.find(val => val.producto_id == this.producto.id);
		if(existente != null){
			Alert.warning('El producto ya fue ingresado')
			return
		}		
		let data = {
			evento_id: Number(this.$route.query.id),
			producto_id: this.producto.id,
			cantidad_inicial: this.cantidad,
		}
		this.eventoService.registrarStock(data).then(response => {
			console.log(response);
			this.eventoService.showEvento(Number(this.$route.query.id));
		});
		this.producto = null;
		this.cantidad = 0;
	}
	eliminarStock(id:number) {
		this.eventoService.eliminarStock(id).then(response => {
			console.log(response)
			this.eventoService.showEvento(Number(this.$route.query.id));

		})
	}
	modalAdicional(stock_id:number, producto:string) {
		this.nombre_producto = producto;
		this.stock_id = stock_id
		this.swModal = true;
	}
	registrarAdicional() {
		if(this.adicional <= 0) {
			Alert.warning('Ingrese una cantidad mayor a cero');
			return;
		}
		let data = {
			stock_id: this.stock_id,
			adicional: this.adicional
		}
		this.eventoService.registrarAdicional(data).then(response => {
			this.eventoService.showEvento(Number(this.$route.query.id));
			this.nombre_producto = '';
			this.swModal = false;
		})
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}

}

