
  import {Vue} from "vue-property-decorator";
  import Component from "vue-class-component";
  import { Alert } from "../../addons/Alert";
  //import { http } from "@/config/HttpClient";
  import { DateTime } from "luxon";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  import router from "../../router";
  import FormBarra from "./Form.vue"
  
  @Component({
    name: "BarrasIndex",
    components: {FormBarra}
  })
  export default class BarrasIndex extends Vue {
    
    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
    private tab:any = null;
    public dialog = false;
    datos2 = [];
    fecha = DateTime.now().toFormat('yyyy-MM-dd');
		swForm = false;
		barra_selected = {}
		
    get barras(): any {
      return this.eventoStore.cajas;
    }
    
    public txtSearch = "";
    swEditar = false
    mounted(){
      this.getBarras();
      //this.getRoles();

    }
    getBarras(){
      this.loading
      this.eventoService.getCajas()
    }
    nuevaBarra(){
			this.swForm = true;
			this.swEditar = false;

    }
    editar(barra:any){
			this.swForm = true;
			this.swEditar = true;
			this.barra_selected = barra
      
    }
   
    close(){
        this.swForm = false;
    }
		
    get loading(): boolean {
      return this.eventoStore.loading;
    }
    
  }
  