
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "BoxTab",
	})

	export default class BoxTab extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	
	valid = true;
	evento_entrada_id = null;
	promotor_id = null;
	cantidad = 0;
	swEditar = false;
	evento_promotor_id = null
	swProducto = false;
	producto_id = null;
	cantidad_producto = 1;
	box_id = 0;
	box = {
		id: '',
		tipo: '',
		cantidad_personas: 0,
		precio_total: 0,
		comision: 0
	}
	box_selected = '';
	vendedor = '';
	swVenta = false;
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	
	
	get productos(): any {
		return this.eventoStore.productos;
	}
	get boxes(): any {
		return this.eventoStore.boxes;
	}

	
	
	
	get totales(): any {
		let  precio = 0, comision = 0, costo_productos = 0, ganancia_liquida = 0;
		this.boxes.reduce((sum,val) => {
			if(val.estado == 'VENDIDO'){
				precio = precio + Number(val.precio_total);
				comision = comision + Number(val.comision);
				val.productos.reduce((suma, valor) => {
					costo_productos = costo_productos + Number(valor.cantidad * valor.producto.costo);
				},0)
				ganancia_liquida = precio - comision - costo_productos;
			}
			

		},0)
		return {precio: Number(precio).toFixed(2), 
						comision: Number(comision).toFixed(2), 
						costo_productos: Number(costo_productos).toFixed(2), 
						ganancia_liquida: Number(ganancia_liquida).toFixed(2), 
					}
	}
	async mounted(){
		//await this.cargarDatos()
		await this.eventoService.getProductos();
	}


	agregarBox() {
		console.log('llega')
		if (!this.$refs.form.validate()) {
			Alert.warning( `Lene todos los campos obligatorios (*)`)
			return;
		}
		if(this.box.cantidad_personas <= 0 || this.box.precio_total <= 0) {
			Alert.warning('Ingrese datos correctos')
			return
		}
		let data = {
			evento_id: this.$route.query.id,
			id: this.box.id,
			nombre: this.box.tipo,
			cantidad_personas: this.box.cantidad_personas,
			precio_total: this.box.precio_total,
			comision: this.box.comision,


		}
		if(!this.swEditar){
			this.eventoService.registrarBox(data).then(response => {
				this.cantidad = 0;
				this.eventoService.showEvento(Number(this.$route.query.id))
			})

		}else {
			this.eventoService.modificarEventoPromotor(this.evento_promotor_id,data).then(response => {
				this.promotor_id = null;
				this.evento_entrada_id = null;
				this.cantidad = 0;
				this.swEditar = false;
				this.eventoService.showEvento(Number(this.$route.query.id))
			})
		}
		
	}
	
	editar(venta:any) {
		this.promotor_id = venta.promotor_id;
		this.evento_entrada_id = venta.evento_entrada_id;
		this.cantidad = venta.cantidad_inicial;
		this.evento_promotor_id = venta.id
		this.swEditar = true;
	}
	eliminarEventoPromotor(id:number) {
		this.eventoService.eliminarEventoPromotor(id).then(response => {
			console.log(response);
				this.eventoService.showEvento(Number(this.$route.query.id))
			})
	}
	
	agregarProducto() {
		if(this.producto_id == null || this.cantidad_producto < 2 ) {
			Alert.warning('Ingrese una cantidad valida');
			return;
		}
		let data = {
			evento_box_id: this.box_id,
			producto_id: this.producto_id,
			cantidad: this.cantidad_producto
		}
		this.eventoService.registrarProductoBox(data).then(response => {
			this.producto_id = null;
			this.cantidad_producto = 1;
			this.swProducto = false;
			this.eventoService.showEvento(Number(this.$route.query.id))
			
		})
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}
	abrirModalProducto(box_id: number) {
		this.swProducto = true;
		this.box_id = box_id;
	}
	abrirModalVenta(box:any) {
		this.box_selected = box;
		this.swVenta = true;
	}
	registrarVenta() {
		if(this.vendedor == '') {
			Alert.warning('Ingrese un vendedor');
			return
		}
		let data = {
			id: this.box_selected.id,
			vendedor: this.vendedor
		}
		this.eventoService.registrarVentaBox(data).then(response => {
			this.vendedor = '';
			this.swVenta = false;
			this.eventoService.showEvento(Number(this.$route.query.id))
		})
	}
	detalleProducto(productos:any): any {
		let total = 0;
		productos.reduce((sum, val) => {
			total = total + Number(val.cantidad * val.producto.costo)
		},0)
		return Number(total).toFixed(2);
	}
	gananciaLiquida(productos:any, precio:number, comision:number) {
		let costo_producto = 0, total = 0;
		productos.reduce((sum, val) => {
			costo_producto = costo_producto + Number(val.cantidad * val.producto.costo)
		},0)
		total = precio - comision - costo_producto;
		return Number(total).toFixed(2);
	}
	

}

