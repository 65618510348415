
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "BalanceTab",
	})

	export default class BalanceTab extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	valid = true;
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	get productos(): any {
		return this.eventoStore.productos;
	}
	get stock(): any {
		return this.eventoStore.stock;
	}
	get cajas(): any {
		return this.eventoStore.cajas;
	}
	get ventas(): any {
		return this.eventoStore.ventas;
	}
	get egresos(): any {
		return this.eventoStore.egresos;
	}
	get artistas(): any {
		return this.eventoStore.artistas;
	}
	get evento_promotores(): any {
		return this.eventoStore.evento_promotores;
	}
	get venta_entradas(): any {
		return this.eventoStore.venta_entradas;
	}
	get boxes(): any {
		let todos = this.eventoStore.boxes;
		let vendidos = todos.filter(val => val.estado == 'VENDIDO');
		return vendidos;
	}

	get pago_artistas() : any {
		let total = 0;
		this.artistas.forEach(element => {
			element.pago_artista.reduce((sum, val) => {
				total += Number(val.monto);
			},0)
		});
		return Number(total).toFixed(2);
	}
	get pagos_varios() : any {
		let total = 0;
		this.egresos.reduce((sum, val) => {
			total += Number(val.monto)
		},0)
		return Number(total).toFixed(2);
	}
	get total_promotores() : any {
		let comision = 0, preventas = 0;
		this.evento_promotores.reduce((sum, val) => {
			comision += Number(val.subtotal_comision);
			preventas += Number(val.subtotal_entradas);

		},0)
		return {comision: Number(comision).toFixed(2), preventas: Number(preventas).toFixed(2)};
	}
	get total_egresos(): any {
		let total = 0;
		this.cajas.forEach(element => {
		total += Number(this.costoCaja(element.id))
		});
		this.boxes.reduce((sum, val) => {
			total += Number(val.comision)
			val.productos.reduce((suma, valor) => {
				total += Number(valor.cantidad * valor.producto.costo);
			},0)
		},0)
		total = total + Number(this.total_promotores.comision) + Number(this.pagos_varios) + Number(this.pago_artistas);
		return Number(total).toFixed(2)
	}
	get total_gastos(): any {
		let total = 0;
		this.egresos.reduce((sum, val) => {
			total += Number(val.monto); 
		},0)
		return Number(total).toFixed(2)
	}
	get entradas_puerta(): any {
		let total = 0;
		this.venta_entradas.reduce((sum, val) => {
			total += val.subtotal
		},0)
		return Number(total).toFixed(2)
	}
	get total_ingresos(): any {
		let total = 0;
		this.cajas.forEach(element => {
		total += Number(this.ventaCaja(element.id))
		});
		this.boxes.reduce((sum, val) => {
			total += Number(val.precio_total)
		},0)
		total = total + Number(this.total_promotores.preventas) + Number(this.entradas_puerta);
		return Number(total).toFixed(2)
	}
	mounted(){
		this.eventoService.getProductos()
		this.eventoService.getCajas()
	}

	costoCaja(caja_id:number) {
		let total = 0;
		this.ventas.reduce((sum, val) => {
			if(val.caja_id == caja_id ) {
				total +=  Number(val.cantidad * val.stock.producto.costo)
			}
		},0)
		return total;
	}
	ventaCaja(caja_id:number) {
		let total = 0;
		this.ventas.reduce((sum, val) => {
			if(val.caja_id == caja_id ) {
				total +=  Number(val.cantidad * val.stock.producto.precio_venta)
			}
		},0)
		return total;
	}
	costoBox(productos:any) {
		let producto = 0;
		productos.reduce((sum, val) => {
			producto = producto + Number(val.cantidad * val.producto.costo)
		},0)
		return Number(producto).toFixed(2);
	}
	
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}

}

