
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "VentasTab",
	})

	export default class VentasTab extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	valid = true;
	productos_evento = [] as Array<any>;
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	get productos(): any {
		return this.eventoStore.productos;
	}
	get stock(): any {
		return this.eventoStore.stock;
	}
	get cajas(): any {
		return this.eventoStore.cajas;
	}
	get ventas(): any {
		return this.eventoStore.ventas;
	}
	get egresos(): any {
		return this.eventoStore.egresos;
	}
	get boxes(): any {
		let todos = this.eventoStore.boxes;
		let vendidos = todos.filter(val => val.estado == 'VENDIDO');
		return vendidos;
	}
	get total_gastos(): any {
		let total = 0;
		this.egresos.reduce((sum, val) => {
			total += Number(val.monto); 
		},0)
		return Number(total).toFixed(2)
	}
	get totales_venta(): any {
		let total_costo_unitario = 0, costo_unitario = 0, precio_venta = 0, total_precio_venta = 0, ganancia_bruta = 0;
		this.stock.reduce((sum, venta) => {
			total_costo_unitario += Number(venta.producto.costo * venta.cantidad_vendido);
			costo_unitario += Number(venta.producto.costo);
			precio_venta += Number(venta.producto.precio_venta);
			total_precio_venta += Number(venta.producto.precio_venta * venta.cantidad_vendido);
			ganancia_bruta += Number((venta.producto.precio_venta * venta.cantidad_vendido) - (venta.producto.costo * venta.cantidad_vendido));

		},0)
		return {total_costo_unitario: Number(total_costo_unitario).toFixed(2), 
						costo_unitario: Number(costo_unitario).toFixed(2), 
						precio_venta: Number(precio_venta).toFixed(2), 
						total_precio_venta: Number(total_precio_venta).toFixed(2), 
						ganancia_bruta: Number(ganancia_bruta).toFixed(2)}
	}
	mounted(){
		this.eventoService.getProductos()
		this.eventoService.getCajas()
	}

	
	ventaCaja(stock_id:number, caja_id:number) {
		let cantidad = 0;
		this.ventas.reduce((sum, val) => {
			if(val.caja_id == caja_id && val.stock_id == stock_id) {
				cantidad = cantidad + Number(val.cantidad)
			}
		},0)
		return cantidad;
	}
	ventaBox(producto_id:number, box_id:number) {
		let cantidad = 0;
		let box = this.boxes.find(val => val.id == box_id)
		let producto = box.productos.find(res => res.producto_id == producto_id)
		if(producto) cantidad = producto.cantidad;
		return cantidad;
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}

}

