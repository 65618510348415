
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "DatosBasicos",
	})

	export default class DatosBasicos extends Vue {	
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	
	valid = true;
	preview = null;
	//image = null;
	image = null;
	imageUrl = '';
	artista = '';
	precio_artista = 0;
	tipo_entrada_id = null;
	artista_id = null;
	swEditar = false;
	artista_pago_id = null
	swModal = false
	fecha_pago = DateTime.now().toFormat("yyyy-MM-dd");
	monto_pago = 0;
	comprobante = null
	evento_artista_id = null
	nombre_artista = ''
	pagos = []
	
	public url_base = `${process.env.VUE_APP_URL_IMAGE}`
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	
	get artistas(): any {
		return this.eventoStore.artistas;
	}
	get total(): any {
		let  total = 0, pagado = 0, saldo = 0;
		this.artistas.reduce((sum,val) => {
			total += Number(val.precio);
			pagado += Number(val.pagado);
			saldo += Number(val.saldo);

		},0)
		return {total: Number(total).toFixed(2), pagado: Number(pagado).toFixed(2), saldo: Number(saldo).toFixed(2) }
	}
	get total_pagos(): any {
		let total = 0;
		if(this.pagos.length) {
			this.pagos.reduce((sum, val) => {
				total += Number(val.monto)
			},0)
		}
		return Number(total).toFixed(2);
	}
	async mounted(){
		//await this.cargarDatos()
		//await this.eventoService.getTipoEntradas();
	}


	agregarArtista() {
		console.log('llega')
		if(this.artista == '' || this.precio_artista <= 0 ) {
			Alert.warning('Ingrese datos correctos')
			return
		}
		let data = {
			evento_id: this.$route.query.id,
			artista: this.artista,
			precio: this.precio_artista,
		}
		if(!this.swEditar){
			this.eventoService.registrarArtista(data).then(response => {
				this.artista = '';
				this.precio_artista = 0;
				this.eventoService.showEvento(Number(this.$route.query.id))
			})

		}else {
			this.eventoService.modificarArtista(Number(this.artista_id),data).then(response => {
				this.artista = '';
				this.precio_artista = 0;
				this.swEditar = false;
				this.eventoService.showEvento(Number(this.$route.query.id))
			})
		}
		
	}
	
	eliminarArtista(index:number) {
		this.artistas.splice(index, 1)

	}
	editar(artista:any) {
		this.artista = artista.artista;
		this.precio_artista = artista.precio;
		this.artista_id = artista.id;
		this.swEditar = true;
	}
	async cargarDatos()
	{
		await this.eventoService.showEvento(Number(this.$route.query.id)).then(res => {
			this.evento_form =  this.evento
			this.imageUrl =  this.url_base+'imagenes/'+this.evento.imagen
		})
	}
	modalPago(artista_id:number, artista: string){
		this.evento_artista_id = artista_id
		this.nombre_artista = artista
		this.swModal = true;
	}
	verPagos(pagos: any) {
		this.pagos = pagos
	}
	registrarPago() {
		if(this.monto_pago <= 0) {
			Alert.warning('El monto de pago no puede ser 0');
			return;
		}
		const formData = new FormData(); 
		formData.append('evento_id',this.$route.query.id);
		formData.append('evento_artista_id',this.evento_artista_id);
		formData.append('fecha',this.fecha_pago);
		formData.append('monto',this.monto_pago);
		formData.append('adjunto',this.comprobante);
		this.eventoService.registrarPago(formData).then(response => {
			this.eventoService.showEvento(Number(this.$route.query.id))
			this.pagos = []
			this.swModal = false
		})
	}
	eliminarPago(id:number) {
		this.eventoService.eliminarPago(id).then(response => {
			this.eventoService.showEvento(Number(this.$route.query.id))
		})
	}
	subtotal_pagado(pagos:any, precio:number) {
		let pagados = 0, saldos = 0;
		pagos.reduce((sum, val) => {
			pagados += Number(val.monto);
		},0)
		return { pagados: Number(pagados).toFixed(2), saldos: Number(precio - pagados).toFixed(2) }
	}
	verificarPermiso():boolean {
		return this.eventoService.validarPermiso();
	}

}

