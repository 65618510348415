import {globalStore} from "@/store/globalStore";
import {http} from "@/config/HttpClient";
import {AxiosResponse} from "axios";
import {UserAuth} from "@/models/UserAuth";
import {Cifrado} from "@/addons/Cifrado";

export class GlobalService {
    private static instance: GlobalService;
    public static getInstance()
    {
        return this.instance || (this.instance = new this());
    }

    private globalStore = globalStore();

    public getFecha() {
        return http.get("getFecha").subscribe({
            next: (response: AxiosResponse) => {
                this.globalStore.fecha = response.data.fecha;
            }
        })
    }

    public checkLogin() {
        return UserAuth.isLogin();
    }

    public getUsuario() {
        return http.get("user").subscribe({
            next: (response: AxiosResponse) => {
                this.globalStore.user = response.data;
            }
        })
    }

    public getPermisos() {
        return http.get(`get_permissions`).subscribe({
            next: (response: AxiosResponse) => {
                this.globalStore.permisos = response.data;
                this.setPermisosInLocalStore();
            }
        })
    }

    private setPermisosInLocalStore() {
        const permisos: any[] = [];
        this.globalStore.permisos.map((permiso: any) => {
            permisos.push({slug: Cifrado.encrypt(permiso.name)});
        });
        const permisosJson = JSON.stringify(permisos);
        localStorage.setItem("permisosCreditos", permisosJson);
    }

    public can(slug: string) {
        return UserAuth.can(slug);
    }

    public getToken(credentials: any): Promise<any> {
        return new Promise((resolve, reject) => {
            http.post('autenticar', credentials).subscribe({
                next: (response: AxiosResponse) => {
                    resolve(response.data);
                    if (response.data.success) {
                        const token = response.data.token.access_token;
                        localStorage.setItem("token_creditos", token);

                        // this.globalStore.token = token;
                        // this.globalStore.user = response.data.usuario;
                        //
                        // this.getPermisos();
                        this.globalStore.isLogin = true;
                        const user = response.data.usuario;
                        return http.get("get_permissions").subscribe({
                            next: (response: AxiosResponse) => {
                                this.globalStore.permisos = response.data;
                                this.globalStore.token = token;
                                this.globalStore.user = user;
                                this.globalStore.isLogin = true;
                                this.setPermisosInLocalStore();
                            }
                        })
                    }
                },
                error(e: any) {
                    reject(e);
                }
            });
        });
    }

    public destroyToken(): Promise<any> {
        this.checkLogin();
        if (this.globalStore.isLogin) {
            return new Promise((resolve) => {
                localStorage.removeItem("token_creditos");
                localStorage.removeItem("permisosCreditos");
                this.globalStore.token = null;
                this.globalStore.user = new UserAuth();
                resolve(null);
            });
        }
        return Promise.resolve(null);
    }
    public cambiarContraseña(data:any) {
        return http.post(`cambiar-password`, data).subscribe({
            next: (response: AxiosResponse) => {
                this.getUsuario();
                this.setPermisosInLocalStore();
            }
        })
    }
}