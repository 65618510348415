
	//import {Vue} from "vue-property-decorator";
	//import Component from "vue-class-component";
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	//import { http } from "@/config/HttpClient";
	import { DateTime } from "luxon";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
	import router from "@/router";

	@Component({
	name: "FormEvento",
	})

	export default class FormEvento extends Vue {	
		@Prop() swForm!: boolean
	private globalStore = globalStore();
	private eventoStore = useEventoStore();
	private eventoService = EventoService.getInstance();
	private tab:any = null;

	evento= {
		id: '',
		detalle: '',
		fecha: DateTime.now().toFormat('yyyy-MM-dd'),
		imagen: '',
	}
	valid = true;
	preview = null;
	//image = null;
	image = null;
	imageUrl = '';
	artista = '';
	precio_artista = 0;
	artistas = [] as Array<any>;
	productos_evento = [] as Array<any>;
	producto = null;
	cantidad = 0;
	tipo_entrada = null;
	precio_preventa = 0;
	precio_puerta = 0;
	comision = 0;
	detalle_entradas = [] as Array<any>
	public dialog = false;
	get loading(): boolean {
		return this.eventoStore.loading;
	}
	get productos(): any {
		return this.eventoStore.productos;
	}
	get tipo_entradas(): any {
		return this.eventoStore.tipo_entradas;
	}
	mounted(){
		this.dialog = this.swForm
		this.eventoService.getProductos()
	}

	guardar(){
		console.log(this.image)
		if (!this.$refs.form.validate()) {
			Alert.warning( `Lene todos los campos obligatorios (*)`)
			return;
		}
		Alert.confirm(`Se realizará el registro del evento ${this.evento.detalle} ${this.evento.fecha}`, 'Registrar Evento?').then(async result => {
			if(result.isConfirmed){
				const formData = new FormData(); 
        formData.append('detalle',this.evento.detalle);
        formData.append('fecha',this.evento.fecha);
        formData.append('imagen',this.image);
				formData.append('detalle_entradas', JSON.stringify(this.detalle_entradas))
				await this.eventoService.registrarEvento(formData).then(response =>{
					this.limpiar()
					this.$emit('close');
				})
			}
		})
	}


	limpiar(){
		this.evento = {
			id: '',
			detalle: '',
			fecha: DateTime.now().toFormat('yyyy-MM-dd'),
			imagen: '',
		}
	}
	close(){
		this.$emit('close');
	}
	createImage(file:any) {
		console.log(file)
		const reader = new FileReader();
		
		reader.onload = (e) => {
			this.imageUrl = e.target.result;
		};
		reader.readAsDataURL(file);
	}
	onFileChange(file:any) {
		if (!file) {
			return;
		}
		this.createImage(file);
	}
	agregarDetalle() {
		console.log('llega')
		if(this.tipo_entrada == null || this.precio_preventa < 0 || this.precio_puerta < 0 || this.comision < 0  ) {
			Alert.warning('Ingrese datos correctos')
			return
		}
		
		this.detalle_entradas.push({
			tipo: this.tipo_entrada.tipo, 
			precio_preventa: this.precio_preventa, 
			precio_puerta: this.precio_puerta, 
			comision: this.comision,
			tipo_entrada_id: this.tipo_entrada.id
		})
		this.precio_preventa = 0;
		this.precio_puerta = 0;
		this.comision = 0;
	}
	agregarProducto() {
		console.log('llega')
		if(this.producto == null || this.cantidad <= 0 ) {
			Alert.warning('Productos: Ingrese datos correctos')
			return
		}
		let existente = null;
		existente = this.productos_evento.find(val => val.producto_id == this.producto.id);
		if(existente != null){
			Alert.warning('El producto ya fue ingresado')
			return
		}		
		this.productos_evento.push({producto: this.producto?.nombre, cantidad: this.cantidad, producto_id: this.producto?.id})
		this.artista = '';
		this.precio_artista = 0;
	}
	quitarDetalle(index:number) {
		this.detalle_entradas.splice(index, 1)

	}

}

