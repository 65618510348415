
	import {Component, Prop, Watch, Vue} from "vue-property-decorator";
	import { Alert } from "@/addons/Alert";
	import { useEventoStore } from '../../store/eventoStore';
	import { globalStore } from '../../store/globalStore';
	import { EventoService } from '../../services/EventoService';
    
	@Component({
		name: "FormUsuario"
	})
	export default class FormUsuario extends Vue {
		@Prop() dialog!: boolean
		@Prop() usuario_selected!: object
		@Prop() swEditar!: boolean
  
		private globalStore = globalStore();
		private eventoStore = useEventoStore();
		private eventoService = EventoService.getInstance();
    
		usuario = {
			id: null,
			name: '',
			email: '',
			caja_id: null,
			estado: '',
			permisos: [],
		};
		valid = true;
    permisos = [
			{id: 1, name: 'Administrador'},
			{id: 2, name: 'Supervisor'},
			{id: 3, name: 'Cajero'},

		]
		mounted(){
			console.log(this.swEditar)
			setTimeout(this.cargarDatos,1200);
		}
		
		close(){
			this.limpiar();
			this.$emit("close");
		}
		guardar(){
			if (!this.$refs.form.validate()) {
				Alert.warning( `Lene todos los campos obligatorios (*)`)
					return;
				}
			if(!this.swEditar){
				Alert.confirm(`Se registrará el usuario ${this.usuario.name} `, 'Registrar nuevo Usuario?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.registrarUsuario(this.usuario).then(response =>{
							this.limpiar()
							this.$emit('close')
							this.$emit('listar')
						})
						
					}
				})
			}else {
				Alert.confirm(`Se realizará la modificacion del usuario`, 'Modificar Usuario?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.modificarUsuario(this.usuario)
						this.limpiar()
						this.$emit('close')
						this.$emit('listar')
					}
				})
			}
			
		}
		cargarDatos(){
			console.log(this.swEditar)
			if(this.swEditar == false){
				this.limpiar()
				return
			}
			console.log('cargar')
			if( Object.entries(this.usuario_selected).length !== 0 && this.usuario_selected.constructor === Object){
				//this.swEditar = false;
				console.log('entra')
				this.usuario = {
					id: this.usuario_selected.id,
					name: this.usuario_selected.name,
					email: this.usuario_selected.email,
					caja_id: this.usuario_selected.caja_id,
					estado: this.usuario_selected.estado,
	
				};
			}
		}
		limpiar(){
			this.producto = {
				id: null,
				nombre: '',
				marca: '',
				costo: 0,
				precio_venta: 0,
				unidad: '',
			};
		}
		get loading(): boolean {
			return this.eventoStore.loading;
		}
		
		get cajas(): any {
			return this.eventoStore.cajas;
		}
      
	}
