
  import {Component, Prop, Watch, Vue} from "vue-property-decorator";
  import { Alert } from "@/addons/Alert";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  
  @Component({
    name: "FormProducto"
  })
  export default class FormProducto extends Vue {
    @Prop() dialog!: boolean
    @Prop() producto_selected!: object
    @Prop() swEditar!: boolean

    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
  
    producto = {
      id: null,
      nombre: '',
      marca: '',
      costo: 0,
      precio_venta: 0,
      unidad: '',
    };
    valid = true;
    categoria_id = null;
    image = null
    detalle_combo = [] as Array<any>;
    producto_combo = null;
    cantidad_producto = 1;
   
    mounted(){
      console.log(this.swEditar)
      setTimeout(this.cargarDatos,1500);
    }
    
    close(){
      this.limpiar();
      this.$emit("close");
    }
    guardar(){
      if (!this.$refs.form.validate()) {
        Alert.warning( `Lene todos los campos obligatorios (*)`)
          return;
        }
        const formData = new FormData(); 
        formData.append('nombre',this.producto.nombre);
        formData.append('marca',this.producto.marca);
        formData.append('costo',this.producto.costo);
        formData.append('precio_venta',this.producto.precio_venta);
        formData.append('unidad',this.producto.unidad);
        formData.append('detalle_combo', JSON.stringify(this.detalle_combo))
				formData.append('imagen', this.image)
				formData.append('categoria_id', this.categoria_id)
      if(!this.swEditar){
        
        Alert.confirm(`Se registrará el producto ${this.producto.nombre} `, 'Registrar nuevo Producto?').then(async result => {
          if(result.isConfirmed){
            await this.eventoService.registrarProducto(formData).then(response =>{
              this.limpiar()
              this.$emit('close')
              this.$emit('listar')
            })
            
          }
        })
      }else {
        Alert.confirm(`Se realizará la modificacion del producto`, 'Modificar Producto?').then(async result => {
          if(result.isConfirmed){
            await this.eventoService.updateProducto(this.producto.id, formData)
            this.limpiar()
            this.$emit('close')
            this.$emit('listar')
          }
        })
      }
      
    }
    cargarDatos(){
      console.log(this.swEditar)
      if(this.swEditar == false){
        this.limpiar()
        return
      }
      console.log('cargar')
      if( Object.entries(this.producto_selected).length !== 0 && this.producto_selected.constructor === Object){
        //this.swEditar = false;
        console.log('entra')
        this.producto = {
          id: this.producto_selected.id,
          nombre: this.producto_selected.nombre,
          marca: this.producto_selected.marca,
          costo: this.producto_selected.costo,
          precio_venta: this.producto_selected.precio_venta,
          unidad: this.producto_selected.unidad,
  
        };
        this.categoria_id = this.producto_selected.categoria_id 
        if(this.producto_selected.combo.length) {
          this.producto_selected.combo.forEach(element => {
            this.detalle_combo.push(
              {producto_id: element.producto_hijo_id, 
              producto: element.producto_hijo.nombre, 
              cantidad: element.cantidad,
              combo_id: element.id
            })
            
          });
        }
      }
    }
    limpiar(){
      this.producto = {
        id: null,
        nombre: '',
        marca: '',
        costo: 0,
        precio_venta: 0,
        unidad: '',
      };
    }
    agregarProducto() {
      this.detalle_combo.push({producto_id: this.producto_combo.id, producto: this.producto_combo.nombre, cantidad: this.cantidad_producto, combo_id: null})
    }
    quitarProducto(index:number) {
		this.detalle_combo.splice(index, 1)

    }
    eliminarCombo(combo_id: number) {
      this.eventoService.eliminarCombo(combo_id).then(res => {
        this.$emit('listar')
        this.limpiar()
        this.detalle_combo = []
        setTimeout(this.cargarDatos,1000);
      })
    }
    get loading(): boolean {
      return this.eventoStore.loading;
    }
    get categorias(): any {
      return this.eventoStore.categorias;
    }
    get productos(): any {
      return this.eventoStore.productos;
    }
    
  }
