import {Funcionario} from "@/models/Funcionario";

import {Cifrado} from "@/addons/Cifrado";

export class UserAuth {
    constructor(
        public id: number = 0,
        public email: string = '',
        public name: string = '',
        public rol: string = '',
        public estado: boolean = false,
        public caja_id: number = 0
    ) {
    }

    public static can(slug: string) {
        const permisos = JSON.parse(localStorage.getItem("permisosCreditos")!);
        return permisos?.find((x:any) => Cifrado.decrypt(x.slug) === slug) !== undefined;
    }

    public static isLogin(): boolean
    {
        return localStorage.getItem('token') !== null
    }
}