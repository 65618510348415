import { render, staticRenderFns } from "./Detalle.vue?vue&type=template&id=44257a86&scoped=true&"
import script from "./Detalle.vue?vue&type=script&lang=ts&"
export * from "./Detalle.vue?vue&type=script&lang=ts&"
import style0 from "./Detalle.vue?vue&type=style&index=0&id=44257a86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44257a86",
  null
  
)

export default component.exports