
  import {Vue} from "vue-property-decorator";
  import Component from "vue-class-component";
  import { Alert } from "../../addons/Alert";
  //import { http } from "@/config/HttpClient";
  import { DateTime } from "luxon";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  import router from "../../router";
  import FormUsuario from "./Form.vue"
  
  @Component({
    name: "UsuariosIndex",
    components: {FormUsuario}
  })
  export default class UsuariosIndex extends Vue {
    
    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
    private tab:any = null;
    public dialog = false;
    datos2 = [];
    fecha = DateTime.now().toFormat('yyyy-MM-dd');
		swForm = false;
		usuario_selected = {}
		
    get usuarios(): any {
      return this.eventoStore.usuarios;
    }
    
    public txtSearch = "";
    swEditar = false
    mounted(){
      this.getUsuarios();
      //this.getRoles();
			this.eventoService.getCajas()


    }
    getUsuarios(){
      this.loading
      this.eventoService.getUsuarios()
        
    }

    
    nuevoUsuario(){
			this.swForm = true;
			this.swEditar = false;

    }
    editar(usuario:any){
			this.swForm = true;
			this.swEditar = true;
			this.usuario_selected = usuario
      
    }
   
    close(){
        this.swForm = false;
    }
		resetPassword(user:any) {
			Alert.confirm(`Se restaurará la contraseña del usuario ${user.name} `, 'Restaurar contraseña?').then(async result => {
					if(result.isConfirmed){
						await this.eventoService.restaurarPassword(user)						
					}
				})
		}
		async cambiarEstado(id:number) {
			await this.eventoService.cambiarEstado(id).then(response => {
				this.getUsuarios();
			})
		}
    
    get cajas(): any {
			return this.eventoStore.cajas;
		}
    get loading(): boolean {
      return this.eventoStore.loading;
    }
    
  }
  