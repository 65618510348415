import Swal, {SweetAlertResult} from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export class Alert {
    static successTotastr(msg: string, titulo = "Ok"): Promise<SweetAlertResult> {
        return Swal.fire({
            toast: true,
            title: titulo,
            position: 'top-end',
            icon: 'success',
            html: msg,
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
        });
    }

    static info(msg: string, titulo = ""): Promise<SweetAlertResult> {
        return Swal.fire({
                icon: 'info',
                title: titulo,
                html: msg,
                confirmButtonText: 'Aceptar',
                focusConfirm: true,
                allowOutsideClick: false,
        });
    }

    static success(msg: string, titulo = "Ok"): Promise<SweetAlertResult> {
        return Swal.fire({
            icon: 'success',
            title: titulo,
            html: msg,
            confirmButtonText: 'Aceptar',
            focusConfirm: true,
            allowOutsideClick: false,
        });
    }

    static errorTop(msg: string): Promise<SweetAlertResult> {
        return Swal.fire({
            icon: 'error', title: 'Error!', html: msg, toast: true, position: 'top-end', timer: 2500,
        });
    }

    static error(msg: string): Promise<SweetAlertResult> {
        return Swal.fire({
            icon: 'error', title: 'Error!', html: msg, confirmButtonText: 'Aceptar'
        });
    }

    static validate(msg: string): Promise<SweetAlertResult> {
        return Swal.fire({
            icon: 'error', title: 'Error de validación...', html: msg,
        });
    }

    /***
     * Confirma a través de un mensaje si la acción es correcta
     * @param {String} msg Mensaje personalizado
     * @param {String} title Título del mensaje
     * */
    static confirm(msg: string, title = "¿Esta seguro?"): Promise<SweetAlertResult> {
        return Swal.fire({
            title: title,
            html: msg,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',

            focusConfirm: true,
            allowOutsideClick: false,
        });
    }

    static confirmDeny(msg: string, title = "¿Esta seguro?", confirmText: string, denyText: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: title,
            html: msg,
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: confirmText,
            denyButtonText: denyText,
            cancelButtonText: 'Cancelar',
        });
    }
    static warning(msg: string): Promise<SweetAlertResult> {
        return Swal.fire({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            background: '#E53935',
            title: 'Warning',
            color: 'white',
            icon: 'warning',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            html: msg,

        })
    }
}
