
  import {Vue} from "vue-property-decorator";
  import Component from "vue-class-component";
  import { DateTime } from "luxon";
  import { useEventoStore } from '../../store/eventoStore';
  import { globalStore } from '../../store/globalStore';
  import { EventoService } from '../../services/EventoService';
  import router from "../../router";
  import FormNew from "./Form.vue"
  
  @Component({
    name: "EventosIndex",
    components: {FormNew}
  })
  export default class EventosIndex extends Vue {
    
    private globalStore = globalStore();
    private eventoStore = useEventoStore();
    private eventoService = EventoService.getInstance();
    public swEditar = false;
    public cotizacion_selected = null;
    public swForm = false;
    public swVer = false;
    public fechaInicial = DateTime.now().minus({days: 6}).toFormat("yyyy-MM-dd");
    public fechaFinal = DateTime.now().plus({days: 6}).toFormat("yyyy-MM-dd");
		public url_base = `${process.env.VUE_APP_URL_IMAGE}`
    swNew = false;
    $refs!: {
      form: HTMLFormElement,
    };
    public txtSearch = "";
    async mounted(){
      await this.getEventos();
		await this.eventoService.getTipoEntradas();

    }
    getEventos(){
      this.loading
      let data = {
				fecha_inicial: this.fechaInicial,
				fecha_final: this.fechaFinal
      }
      this.eventoService.getEventos(data)
    }
    nuevo(){
      this.swNew = true;
			//router.push({name: 'nuevo-evento'});
    }
    editar(cotizacion:any){
      this.swForm = true;
      this.swEditar = true;
      this.cotizacion_selected = cotizacion;
    }
        
    get eventos(): any {
      return this.eventoStore.eventos;
    }
    get pagination(): any {
      return this.eventoStore.pagination;
    }
    get loading(): boolean {
      return this.eventoStore.loading;
    }
    
  }
  