import {defineStore} from "pinia";
import {UserAuth} from "@/models/UserAuth";
import {DateTime} from "luxon";

export const globalStore = defineStore("globalStore", {
    state: () =>
        ({
            loading: false as boolean,
            urlYakusela: `${process.env.VUE_APP_URL_YAKUSELA}` as string,
            fecha: DateTime.now().toFormat("yyyy-MM-dd"),

            token: localStorage.getItem('token_creditos'),
            user: new UserAuth() as UserAuth,
            permisos: [] as any[],

            //isLogin: UserAuth.isLogin() as boolean
            isLogin : UserAuth.isLogin()
        })
});