import * as CryptoJS from 'crypto-js';

export class Cifrado {
    private static KEY = `${process.env.VUE_APP_ENCRYPTION_KEY}`;

    public static encrypt(cad: string): string {
        return CryptoJS.AES.encrypt(cad, this.KEY).toString();
    }

    public static decrypt(cad: string): string {
        return CryptoJS.AES.decrypt(
            cad,
            this.KEY
        ).toString(CryptoJS.enc.Utf8);
    }
}