import Vue from 'vue';
import {DateTime} from 'luxon';
import numbers from 'numeros_to_words';

Vue.filter('capitalize', (value) => {
    if (!value) {
        return '';
    } else {
        let values = value.split(' ');
        value = '';
        values.map((word, index) => {
            word = word.toString();
            if (index === 0) {
                value += `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
            } else {
                value += ` ${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
            }
        });
        return value;
    }
});
Vue.filter('lowercase', (value) => {
    if (!value) {
        return '';
    } else {
        return value.toString().toLowerCase();
    }
});

Vue.filter('round', (value) => {
    if (!value) {
        return '0.00';
    } else {
        value = +value;
        if (isNaN(value)) return NaN; // Shift
        value = value.toString().split('e');
        value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + 2) : 2)));
        // Shift back
        value = value.toString().split('e');
        return (+(value[0] + 'e' + (value[1] ? (+value[1] - 2) : -2))).toFixed(2);
    }
});

Vue.filter('uppercase', (value) => {
    if (!value) {
        return '';
    } else {
        return value.toString().toUpperCase();
    }
});

Vue.filter('boolean', (value) => {
    return value ? 'Si' : 'No';
});

Vue.filter('round2', (value) => {
    return Math.round(value * 100) / 100;
});
Vue.filter('cuf', (value) => {
    let cuf = '';
    for (let i = 0; i < value.length; i++) {
        if ((i + 1) % 20 == 0) {
            cuf += value.substring(i, i + 1) + " ";
        } else {
            cuf += value.substring(i, i + 1);
        }
    }
    return cuf;
});

Vue.filter('literal', (value) => {
    numbers().Config._setSingular('BOLIVIANO');
    numbers().Config._setPlural('BOLIVIANOS');
    numbers().Config._setCentsSingular('CENTAVO');
    numbers().Config._setCentsPlural('CENTAVOS');
    return numbers(Math.round(value * 100) / 100).toString();
});

Vue.filter('fullDate', (value) => {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL);
});

Vue.filter('dmY', (value) => {
    return DateTime.fromISO(value).toFormat('dd/MM/yyyy');
});
Vue.filter('cantidad', (value) => {
    if ( value == 0 || value == '0' || value == undefined || value == null) {
        return '1';
    }else{
        return value;
    }
});

// Vue.filter('localDate', (value) => {
//     return DateTime.fromISO(value).toLocaleString();
// });
Vue.filter('mediumDate', (value) => {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
});

Vue.filter('shortDate', (value) => {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
});

Vue.filter('mediumDateTime', (value) => {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
});
Vue.filter('mediumDateTimeWithSeconds', (value) => {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
});

Vue.filter('onlyDayMonthDate', (value) => {
    return DateTime.fromISO(value).setLocale('es').toFormat("dd 'de' LLLL");
});

Vue.filter('localDate', (value) => {
    return DateTime.fromSQL(value).toLocaleString(DateTime.DATE_SHORT);
});

Vue.filter('localDateHour', (value) => {
    return DateTime.fromSQL(value).toLocaleString(DateTime.DATETIME_SHORT);
});

Vue.filter('month', (value) => {
    switch (value) {
        case 1:
            return 'Enero';
        case 2:
            return 'Febrero';
        case 3:
            return 'Marzo';
        case 4:
            return 'Abril';
        case 5:
            return 'Mayo';
        case 6:
            return 'Junio';
        case 7:
            return 'Julio';
        case 8:
            return 'Agosto';
        case 9:
            return 'Septiembre';
        case 10:
            return 'Octubre';
        case 11:
            return 'Noviembre';
        case 12:
            return 'Diciembre';
    }
});

Vue.filter('departamento', (value) => {
    const departamentos = [
        {
            label: 'Oruro',
            value: 'ORU'
        },
        {
            label: 'La Paz',
            value: 'LPZ'
        },
        {
            label: 'Cochabamba',
            value: 'CBBA'
        },
        {
            label: 'Chuquisaca',
            value: 'CHQ'
        },
        {
            label: 'Potosi',
            value: 'PSI'
        },
        {
            label: 'Tarija',
            value: 'TJA'
        },
        {
            label: 'Santa Cruz',
            value: 'SCZ'
        },
        {
            label: 'Beni',
            value: 'BNI'
        },
        {
            label: 'Pando',
            value: 'PND'
        }
    ];
    const result = departamentos.find((departamento) => {
        return departamento.value === value;
    });
    return result.label;
});